import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import useReporting from '../../hooks/useReporting.js';
import StatTable from './StatTable.jsx';
import {
    itDeptOptions,
    timePeriodOptions
} from '../../config/dropdown-options.js';
import useUsers from '../../hooks/useUsers.js';

/**
 * Component for displaying quick stats on the dashboard.
 * @returns {JSX.Element} JSX element representing the QuickStats component.
 */
const QuickStats = () => {
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(
        timePeriodOptions[0]
    );
    const [selectedItSegment, setSelectedItSegment] = useState(
        itDeptOptions[0]
    );
    const { loading, error, table, tables, runQuery } = useReporting();
    const { getUser, user } = useUsers();

    /**
     * Fetches data from the server.
     * @returns {void}
     */
    useEffect(() => {
        const fetchData = async () => {
            const token = sessionStorage.getItem('accessToken');
            const decoded = jwtDecode(token);

            const returnedUser = await getUser(decoded.sub);
            if (returnedUser && returnedUser.role.name === 'manager') {
                await runQuery({
                    timePeriod: selectedTimePeriod.value,
                    itSegment: selectedItSegment.value
                });
            } else {
                await runQuery({
                    timePeriod: selectedTimePeriod.value,
                    userId: decoded.sub
                });
            }
        };

        fetchData();
    }, [selectedTimePeriod, selectedItSegment]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'normal',
                height: '100%',
                width: '100%'
            }}>
            <h2>Quick Stats</h2>
            <br />
            <div style={{ width: '50%' }}>
                <Select
                    options={timePeriodOptions}
                    onChange={(selectedOption) =>
                        setSelectedTimePeriod(selectedOption)
                    }
                    value={selectedTimePeriod}
                />
                {user && user.role.name === 'manager' && (
                    <>
                        <br />
                        <Select
                            options={itDeptOptions}
                            onChange={(selectedOption) =>
                                setSelectedItSegment(selectedOption)
                            }
                            value={selectedItSegment}
                        />
                    </>
                )}
            </div>
            <br />
            <div>
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {table && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <StatTable
                            title={table.title}
                            key={uuidv4()}
                            rows={table.rows}
                            columnHeaders={table.headers}
                        />
                    </div>
                )}
                {tables && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        {tables.map((t) => (
                            <StatTable
                                title={t.title}
                                key={uuidv4()}
                                rows={t.rows}
                                columnHeaders={t.headers}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuickStats;
